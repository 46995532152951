import { AppDomain } from 'types/enums/AppDomain';
import { AppEnv } from 'types/enums/AppEnv';

export interface SiteConfig {
  id: number;
  groupId: number;
  domain: AppDomain;
  gtm: Partial<
    Record<
      AppEnv.Production | AppEnv.Development | AppEnv.Stage | AppEnv.Hook,
      string
    >
  >;
  googleAuthClientId?: Partial<
    Record<
      AppEnv.Production | AppEnv.Development | AppEnv.Stage | AppEnv.Hook,
      string
    >
  >;
  reCaptcha?: Partial<
    Record<
      AppEnv.Production | AppEnv.Development | AppEnv.Stage | AppEnv.Hook,
      string
    >
  >;
  tiktokId?: string;
  sumsubToken?: Partial<
    Record<
      AppEnv.Production | AppEnv.Development | AppEnv.Stage | AppEnv.Hook,
      string
    >
  >;
  sitename?: string;
  supportPhone?: string;
}

export const SITES_CONFIGS: Record<string, SiteConfig> = {
  HOOKUPERS: {
    id: 1,
    groupId: 1,
    domain: AppDomain.Hookupers,
    gtm: {},
    googleAuthClientId: {
      [AppEnv.Hook]:
        '836368529987-gmge199s05jbcuv46iam51evst5oa19p.apps.googleusercontent.com',
      [AppEnv.Production]:
        '1042857476632-v0vrnatdf4sr8t8vl6qhvubjo9fq19b6.apps.googleusercontent.com',
    },
  },
  BESTHOOKUPER: {
    id: 2,
    groupId: 1,
    domain: AppDomain.BestHookuper,
    gtm: {
      [AppEnv.Production]: 'GTM-5JBPMKR',
    },
    googleAuthClientId: {
      [AppEnv.Hook]:
        '836368529987-gmge199s05jbcuv46iam51evst5oa19p.apps.googleusercontent.com',
      [AppEnv.Production]:
        '1042857476632-v0vrnatdf4sr8t8vl6qhvubjo9fq19b6.apps.googleusercontent.com',
    },
  },
  HOOKUPPARADISE: {
    id: 3,
    groupId: 1,
    domain: AppDomain.HookupParadise,
    gtm: {},
  },
  DATES_ME: {
    id: 4,
    groupId: 4,
    domain: AppDomain.DatesMe,
    gtm: {
      [AppEnv.Production]: 'GTM-TGHZ922',
    },
    tiktokId: 'CME1ILRC77UFQ8DF52KG',
  },
  MEPLUSYOU: {
    id: 5,
    groupId: 2,
    domain: AppDomain.MePlusYou,
    gtm: {},
  },

  // MIDS
  MEAMO: {
    id: 6,
    groupId: 3,
    domain: AppDomain.Meamo,
    gtm: {},
  },
  MATCHESS: {
    id: 7,
    groupId: 3,
    domain: AppDomain.Matchess,
    gtm: {},
  },
  UNITEUS: {
    id: 8,
    groupId: 3,
    domain: AppDomain.Uniteus,
    gtm: {},
  },
  PPL2PPL: {
    id: 9,
    groupId: 3,
    domain: AppDomain.Ppl2ppl,
    gtm: {},
  },
  THEUNITE: {
    id: 10,
    groupId: 3,
    domain: AppDomain.Theunite,
    gtm: {},
  },
  FINDER_SINGLES: {
    id: 11,
    groupId: 3,
    domain: AppDomain.FinderSingles,
    gtm: {},
  },
  JOY_FINDER: {
    id: 12,
    groupId: 3,
    domain: AppDomain.JoyFinder,
    gtm: {},
  },
  FLIRT_GURU: {
    id: 13,
    groupId: 3,
    domain: AppDomain.FlirtGuru,
    gtm: {},
  },
  FLIRT_DISCOVERY: {
    id: 14,
    groupId: 3,
    domain: AppDomain.FlirtDiscovery,
    gtm: {},
  },
  ADVENTURE_RELATION: {
    id: 15,
    groupId: 3,
    domain: AppDomain.AdventureRelation,
    gtm: {},
  },
  LOCALS_DATE: {
    id: 16,
    groupId: 2,
    domain: AppDomain.LocalsDate,
    gtm: {},
  },
  LUST_STATION: {
    id: 17,
    groupId: 1,
    domain: AppDomain.LustStation,
    gtm: {},
    googleAuthClientId: {
      [AppEnv.Hook]:
        '836368529987-gmge199s05jbcuv46iam51evst5oa19p.apps.googleusercontent.com',
      [AppEnv.Production]:
        '1042857476632-v0vrnatdf4sr8t8vl6qhvubjo9fq19b6.apps.googleusercontent.com',
    },
  },
  LOCALSJOY: {
    id: 18,
    groupId: 1,
    domain: AppDomain.Localsjoy,
    gtm: {},
    googleAuthClientId: {
      [AppEnv.Hook]:
        '836368529987-gmge199s05jbcuv46iam51evst5oa19p.apps.googleusercontent.com',
      [AppEnv.Production]:
        '1042857476632-v0vrnatdf4sr8t8vl6qhvubjo9fq19b6.apps.googleusercontent.com',
    },
  },
  DREAMS_DATE: {
    id: 19,
    groupId: 3,
    domain: AppDomain.DreamsDate,
    gtm: {},
  },
  NEVER_ALONES: {
    id: 20,
    groupId: 3,
    domain: AppDomain.NeverAlones,
    gtm: {},
  },
  DATEA_SOUL: {
    id: 21,
    groupId: 3,
    domain: AppDomain.DateaSoul,
    gtm: {},
  },
  DATEA_SOULMATE: {
    id: 22,
    groupId: 3,
    domain: AppDomain.DateaSoulmate,
    gtm: {},
  },
  YOUR_LOVE4LIFE: {
    id: 23,
    groupId: 3,
    domain: AppDomain.YourLove4Life,
    gtm: {},
  },
  TIAMO: {
    id: 24,
    groupId: 3,
    domain: AppDomain.Tiamo,
    gtm: {},
  },
  SIMPLEDATESME: {
    id: 25,
    groupId: 5,
    domain: AppDomain.SimpleDatesMe,
    gtm: {
      [AppEnv.Production]: 'GTM-KPN8CQT',
    },
  },
  SWEATHEARTSOUL: {
    id: 27,
    groupId: 3,
    domain: AppDomain.SweatHeartSoul,
    gtm: {},
    supportPhone: '+1 (380) 333-9560',
  },
  ROMANTICWAY: {
    id: 28,
    groupId: 3,
    domain: AppDomain.RomanticWay,
    gtm: {},
  },
  ADVENTUROUSDATE: {
    id: 29,
    groupId: 3,
    domain: AppDomain.AdventuroUsDate,
    gtm: {},
  },
  THEJOYDATE: {
    id: 30,
    groupId: 3,
    domain: AppDomain.TheJoyDate,
    gtm: {},
  },
  LOVEPATH: {
    id: 31,
    groupId: 3,
    domain: AppDomain.LovePath,
    gtm: {},
  },
  GETPARAMOUR: {
    id: 32,
    groupId: 3,
    domain: AppDomain.GetParamour,
    gtm: {},
  },
  TRIPTOLOVE: {
    id: 33,
    groupId: 3,
    domain: AppDomain.TripToLove,
    gtm: {},
  },
  SIMPLEDATESCO: {
    id: 34,
    groupId: 7,
    domain: AppDomain.SimpleDatesCo,
    gtm: {
      [AppEnv.Production]: 'GTM-KPN8CQT',
    },
  },

  // * DESCRIPTORS CONFIGS START
  PaysPlay: {
    id: 36,
    groupId: 6,
    domain: AppDomain.PaysPlay,
    gtm: {},
  },
  Bst4flPay: {
    id: 37,
    groupId: 6,
    domain: AppDomain.Bst4flPay,
    gtm: {},
  },
  ArmBills: {
    id: 38,
    groupId: 6,
    domain: AppDomain.ArmBills,
    gtm: {},
  },
  Armor2Pay: {
    id: 39,
    groupId: 6,
    domain: AppDomain.Armor2Pay,
    gtm: {},
  },
  Bill4Info: {
    id: 40,
    groupId: 6,
    domain: AppDomain.Bill4Info,
    gtm: {},
  },
  BillingAct: {
    id: 41,
    groupId: 6,
    domain: AppDomain.BillingAct,
    gtm: {},
  },
  BillNow24: {
    id: 42,
    groupId: 6,
    domain: AppDomain.BillNow24,
    gtm: {},
  },
  Charge2Rock: {
    id: 43,
    groupId: 6,
    domain: AppDomain.Charge2Rock,
    gtm: {},
  },
  ChecksPay: {
    id: 44,
    groupId: 6,
    domain: AppDomain.ChecksPay,
    gtm: {},
  },
  HelpInBill: {
    id: 45,
    groupId: 6,
    domain: AppDomain.HelpInBill,
    gtm: {},
  },
  Hub4Bill: {
    id: 46,
    groupId: 6,
    domain: AppDomain.Hub4Bill,
    gtm: {},
  },
  Paid4Act: {
    id: 47,
    groupId: 6,
    domain: AppDomain.Paid4Act,
    gtm: {},
  },
  PaiFastly: {
    id: 48,
    groupId: 6,
    domain: AppDomain.PaiFastly,
    gtm: {},
  },
  Pay2Connect: {
    id: 49,
    groupId: 6,
    domain: AppDomain.Pay2Connect,
    gtm: {},
  },
  PayInSec: {
    id: 50,
    groupId: 6,
    domain: AppDomain.PayInSec,
    gtm: {},
  },
  Payment2Rock: {
    id: 51,
    groupId: 6,
    domain: AppDomain.Payment2Rock,
    gtm: {},
  },
  FlrtPay: {
    id: 52,
    groupId: 6,
    domain: AppDomain.FlrtPay,
    gtm: {},
  },
  BillTime24: {
    id: 53,
    groupId: 6,
    domain: AppDomain.BillTime24,
    gtm: {},
  },
  Debit2Pay: {
    id: 54,
    groupId: 6,
    domain: AppDomain.Debit2Pay,
    gtm: {},
  },
  Info_Pay24: {
    id: 55,
    groupId: 6,
    domain: AppDomain.Info_Pay24,
    gtm: {},
  },
  InfoPay24: {
    id: 56,
    groupId: 6,
    domain: AppDomain.InfoPay24,
    gtm: {},
  },
  PaymentInfo24: {
    id: 57,
    groupId: 6,
    domain: AppDomain.PaymentInfo24,
    gtm: {},
  },
  BillInfo24: {
    id: 58,
    groupId: 6,
    domain: AppDomain.BillInfo24,
    gtm: {},
  },
  ChPay24: {
    id: 59,
    groupId: 6,
    domain: AppDomain.ChPay24,
    gtm: {},
  },
  BestBill: {
    id: 60,
    groupId: 6,
    domain: AppDomain.BestBill,
    gtm: {},
  },
  BestBill24: {
    id: 61,
    groupId: 6,
    domain: AppDomain.BestBill24,
    gtm: {},
  },
  BestPay24: {
    id: 62,
    groupId: 6,
    domain: AppDomain.BestPay24,
    gtm: {},
  },
  DrmsPay: {
    id: 63,
    groupId: 6,
    domain: AppDomain.DrmsPay,
    gtm: {},
  },
  BvlntnPay: {
    id: 64,
    groupId: 6,
    domain: AppDomain.BvlntnPay,
    gtm: {},
  },
  PplPay: {
    id: 65,
    groupId: 6,
    domain: AppDomain.PplPay,
    gtm: {},
  },
  DtngPay: {
    id: 66,
    groupId: 6,
    domain: AppDomain.DtngPay,
    gtm: {},
  },
  LklBill: {
    id: 67,
    groupId: 6,
    domain: AppDomain.LklBill,
    gtm: {},
  },
  BstfrflrtPay: {
    id: 68,
    groupId: 6,
    domain: AppDomain.BstfrflrtPay,
    gtm: {},
  },
  JfndrPay: {
    id: 69,
    groupId: 6,
    domain: AppDomain.JfndrPay,
    gtm: {},
  },
  LvpthPay: {
    id: 70,
    groupId: 6,
    domain: AppDomain.LvpthPay,
    gtm: {},
  },
  // * DESCRIPTORS CONFIGS END

  // MIDS START
  RelationshipNest: {
    id: 71,
    groupId: 3,
    domain: AppDomain.RelationshipNest,
    gtm: {},
    reCaptcha: {
      [AppEnv.Production]: '6LdIcvIpAAAAAIBkNJ97yJHJIUVKuSR9yLmlOp36',
    },
  },
  CupidKeys: {
    id: 72,
    groupId: 3,
    domain: AppDomain.CupidKeys,
    gtm: {},
    reCaptcha: {
      [AppEnv.Production]: '6LdIcvIpAAAAAIBkNJ97yJHJIUVKuSR9yLmlOp36',
    },
  },
  AmourPulse: {
    id: 73,
    groupId: 3,
    domain: AppDomain.AmourPulse,
    gtm: {},
    reCaptcha: {
      [AppEnv.Production]: '6LdIcvIpAAAAAIBkNJ97yJHJIUVKuSR9yLmlOp36',
    },
  },
  MyConnectly: {
    id: 74,
    groupId: 3,
    domain: AppDomain.MyConnectly,
    gtm: {},
    reCaptcha: {
      [AppEnv.Production]: '6LdIcvIpAAAAAIBkNJ97yJHJIUVKuSR9yLmlOp36',
    },
  },
  TenderTalkers: {
    id: 75,
    groupId: 3,
    domain: AppDomain.TenderTalkers,
    gtm: {},
    reCaptcha: {
      [AppEnv.Production]: '6LdIcvIpAAAAAIBkNJ97yJHJIUVKuSR9yLmlOp36',
    },
  },
  // MIDS END

  // * DESCRIPTORS CONFIGS START
  TndPay: {
    id: 76,
    groupId: 6,
    domain: AppDomain.TndPay,
    gtm: {},
  },
  APay24: {
    id: 77,
    groupId: 6,
    domain: AppDomain.APay24,
    gtm: {},
  },
  NstPay24: {
    id: 78,
    groupId: 6,
    domain: AppDomain.NstPay24,
    gtm: {},
  },
  ConnectPay24: {
    id: 79,
    groupId: 6,
    domain: AppDomain.ConnectPay24,
    gtm: {},
  },
  // * DESCRIPTORS CONFIGS END

  // MIDS START
  SupremeDate: {
    id: 80,
    groupId: 3,
    domain: AppDomain.SupremeDate,
    gtm: {},
  },
  TheCupies: {
    id: 81,
    groupId: 3,
    domain: AppDomain.TheCupies,
    gtm: {},
  },
  LookLie: {
    id: 82,
    groupId: 3,
    domain: AppDomain.LookLie,
    gtm: {},
  },
  PerfectDatings: {
    id: 83,
    groupId: 3,
    domain: AppDomain.PerfectDatings,
    gtm: {},
  },
  DatingForLife: {
    id: 84,
    groupId: 3,
    domain: AppDomain.DatingForLife,
    gtm: {},
  },
  ForeverLover: {
    id: 85,
    groupId: 3,
    domain: AppDomain.ForeverLover,
    gtm: {},
  },
  FlirtingLover: {
    id: 86,
    groupId: 3,
    domain: AppDomain.FlirtingLover,
    gtm: {},
  },
  DatingsLover: {
    id: 87,
    groupId: 3,
    domain: AppDomain.DatingsLover,
    gtm: {},
  },
  BeValentine: {
    id: 88,
    groupId: 3,
    domain: AppDomain.BeValentine,
    gtm: {},
    sumsubToken: {
      [AppEnv.Hook]:
        'sbx:ZRC7k0WORRC5OCjl3Xvbhuxt.SxOJsyjty82OKX9hOljxm0UzLbbRw2T9',
      [AppEnv.Production]:
        'sbx:ZRC7k0WORRC5OCjl3Xvbhuxt.SxOJsyjty82OKX9hOljxm0UzLbbRw2T9',
    },
  },
  OnesValentine: {
    id: 89,
    groupId: 3,
    domain: AppDomain.OnesValentine,
    gtm: {},
    sumsubToken: {
      [AppEnv.Hook]:
        'sbx:ZRC7k0WORRC5OCjl3Xvbhuxt.SxOJsyjty82OKX9hOljxm0UzLbbRw2T9',
      [AppEnv.Production]:
        'sbx:ZRC7k0WORRC5OCjl3Xvbhuxt.SxOJsyjty82OKX9hOljxm0UzLbbRw2T9',
    },
  },
  DayDreamme: {
    id: 90,
    groupId: 3,
    domain: AppDomain.DayDreamme,
    gtm: {},
    sumsubToken: {
      [AppEnv.Hook]:
        'sbx:ZRC7k0WORRC5OCjl3Xvbhuxt.SxOJsyjty82OKX9hOljxm0UzLbbRw2T9',
      [AppEnv.Production]:
        'sbx:ZRC7k0WORRC5OCjl3Xvbhuxt.SxOJsyjty82OKX9hOljxm0UzLbbRw2T9',
    },
  },
  DayDreamsDate: {
    id: 91,
    groupId: 3,
    domain: AppDomain.DayDreamsDate,
    gtm: {},
    sumsubToken: {
      [AppEnv.Hook]:
        'sbx:ZRC7k0WORRC5OCjl3Xvbhuxt.SxOJsyjty82OKX9hOljxm0UzLbbRw2T9',
      [AppEnv.Production]:
        'sbx:ZRC7k0WORRC5OCjl3Xvbhuxt.SxOJsyjty82OKX9hOljxm0UzLbbRw2T9',
    },
  },
  Delightedus: {
    id: 92,
    groupId: 3,
    domain: AppDomain.Delightedus,
    gtm: {},
    sumsubToken: {
      [AppEnv.Hook]:
        'sbx:ZRC7k0WORRC5OCjl3Xvbhuxt.SxOJsyjty82OKX9hOljxm0UzLbbRw2T9',
      [AppEnv.Production]:
        'sbx:ZRC7k0WORRC5OCjl3Xvbhuxt.SxOJsyjty82OKX9hOljxm0UzLbbRw2T9',
    },
  },
  BestForFlirt: {
    id: 93,
    groupId: 3,
    domain: AppDomain.BestForFlirt,
    gtm: {},
  },
  PerfectFlirt: {
    id: 94,
    groupId: 3,
    domain: AppDomain.PerfectFlirt,
    gtm: {},
  },
  MeetLie: {
    id: 95,
    groupId: 3,
    domain: AppDomain.MeetLie,
    gtm: {},
  },
  KlubMe: {
    id: 96,
    groupId: 3,
    domain: AppDomain.KlubMe,
    gtm: {},
  },
  Ppls: {
    id: 97,
    groupId: 3,
    domain: AppDomain.Ppls,
    gtm: {},
  },
  Hitchii: {
    id: 98,
    groupId: 3,
    domain: AppDomain.Hitchii,
    gtm: {},
  },
  SociableSpots: {
    id: 99,
    groupId: 3,
    domain: AppDomain.SociableSpots,
    gtm: {},
  },
  MeetNests: {
    id: 100,
    groupId: 3,
    domain: AppDomain.MeetNests,
    gtm: {},
  },
  PeopleMeetClub: {
    id: 101,
    groupId: 3,
    domain: AppDomain.PeopleMeetClub,
    gtm: {},
  },
  GotLinkedNow: {
    id: 102,
    groupId: 3,
    domain: AppDomain.GotLinkedNow,
    gtm: {},
  },
  MeetHarbor: {
    id: 103,
    groupId: 3,
    domain: AppDomain.MeetHarbor,
    gtm: {},
  },
  RelationTunes: {
    id: 104,
    groupId: 3,
    domain: AppDomain.RelationTunes,
    gtm: {},
  },
  DateMateHub: {
    id: 105,
    groupId: 3,
    domain: AppDomain.DateMateHub,
    gtm: {},
  },
  LinkupNook: {
    id: 106,
    groupId: 3,
    domain: AppDomain.LinkupNook,
    gtm: {},
  },
  TheLinkupers: {
    id: 107,
    groupId: 3,
    domain: AppDomain.TheLinkupers,
    gtm: {},
  },
  SoulMatesLab: {
    id: 108,
    groupId: 3,
    domain: AppDomain.SoulMatesLab,
    gtm: {},
  },
  FairyDates: {
    id: 109,
    groupId: 3,
    domain: AppDomain.FairyDates,
    gtm: {},
  },
  WaitForMe: {
    id: 110,
    groupId: 3,
    domain: AppDomain.WaitForMe,
    gtm: {},
  },
  LookFor4you: {
    id: 111,
    groupId: 3,
    domain: AppDomain.LookFor4you,
    gtm: {},
  },
  TheManWoman: {
    id: 112,
    groupId: 3,
    domain: AppDomain.TheManWoman,
    gtm: {},
  },
  UsTogether: {
    id: 113,
    groupId: 3,
    domain: AppDomain.UsTogether,
    gtm: {},
  },
  AmorPassion: {
    id: 115,
    groupId: 3,
    domain: AppDomain.AmorPassion,
    gtm: {},
  },
  AmoreAffair: {
    id: 116,
    groupId: 3,
    domain: AppDomain.AmoreAffair,
    gtm: {},
  },
  AmoreLover: {
    id: 117,
    groupId: 3,
    domain: AppDomain.AmoreLover,
    gtm: {},
  },
  AmorFlames: {
    id: 118,
    groupId: 3,
    domain: AppDomain.AmorFlames,
    gtm: {},
  },
  AmoreDesire: {
    id: 119,
    groupId: 3,
    domain: AppDomain.AmoreDesire,
    gtm: {},
  },
  // MIDS END

  JustLovebirds: {
    id: 120,
    groupId: 7,
    domain: AppDomain.JustLovebirds,
    gtm: {
      [AppEnv.Production]: 'GTM-PCJP9K8N',
    },
  },
};

export const DEFAULT_DEV_SITE_DOMAIN = 'devo4qa.com';
export const DEFAULT_PRODUCTION_SITE_DOMAIN = SITES_CONFIGS.HOOKUPERS.domain;
export const DEFAULT_SITE_ID = SITES_CONFIGS.HOOKUPERS.id;
